
const ObNam = () => {
    return(
	<div className="container">
		<section className="about-us">
			<h3  id="contents">Оглавление:</h3>
			<ol className="about-us__contetns">
				<li><a href="#">История возрождения</a></li>
				<li><a href="#">Во что мы верим</a></li>
				<li><a href="#">Интересные факты об нас</a></li>
				<li><a href="#">Как <span>докатиться</span> вступить в наши ряды</a></li>
				<li><a href="#">Поддержать проект</a></li>
			</ol>

			<article>
				<h4>История возрождения</h4>
				<p>Это вероисповедание, зародилось в одной маленькой головке, одного маленького ёжика. Головка его была мала но в ней гулял сильный и очень вольный ветер (кто-то форточку забыл закрыть, получился сквозняк). С этого сквозняка то все и началось...</p>

				<p>Шквальный порыв холодного осенньего ветра принес с собой шуршашие опавшие листья, горы мусора и маленького нарисованного ёжика. Ёжик стряхнул с себя наколовшуюся на иголки листву, поковырялся в горках мусора и наконец обратил свое внимание на владельца головки, в которую попал. Он забавно пощурился, дернул носом и дружелюбно помахал лапой. Владелец головки (в дальнейшем, Пророк) машинально помахал лапкой в ответ, он внимательно рассматривал своего нежданного гостя. Гость был не велик ростом, но зато его животик выглядел в полне авторитетно и внушал уважение. Ёжик был нарисован на кусочке обычной школьной тетрадки в клеточку и аккуратно вырезан по контуру, при всем при этом ёжик не выглядел плоским и двухмерным, даже на оборот. Всем своим видом ёжик давал понять о глубине своей натуры и масштабности мышления.</p>

				<p>Прилитевший ёжик времени не терял, он тоже изучал соего нового знакомого. При этом он почесывал свою заросшую головку корявой, когтистой граблей лапкой и сосредоточенно шевлил пальцами на нижних лапках.</p>
				<p> --У тебя мама не учительница математики? - спросил он, сморщивая мордочку</p>
				<p> -- Нет, она у меня на почте работает. - ответил будущий пророк, удивленный столь странным вопроссом.</p>
				<p> -- Это я так, на всякий случай. Чтобы не наступать на те же грабли опять - ответил ёжик</p>
				<p> -- А что за грабли? - поинтерессовался хозяин головки - Осенью много граблей, а ты о каких?</p>
				<p> -- Садись. - велел ёжик - История не длинная но конца у нее пока нету.</p>
				<p>И собеседники уселись на появившуюся лавочку (в голове и не такое появляется), ёжик заболтал своими коротенькими ножками и начал свой рассказ.</p>
				<p> -- В виде, в которм ты видиш меня сейчас я появился совсем не давно. Меня нарисовал в своей тетрадке один очень смышленный 7-икласник, до этого я жил в его голове. Мы были близкими друзьями, болтатли по поводу и без поводу, я давл ему много полезных советов и всегда помогал в сложных ситуациях, в общем, не разлей вода (тем более не разлей в “котелке”). И вот однажды нас посетила идея, как много мы бы могли натворить навоять сотворить если бы, я мог существовать вне его головы. Мы загорелись этой идеей и начали выщитывать как это можно воплотить в реальность. После длительных расчетов мы определили подходящее время для исполнения своего плана, 2-ое сентебря 11:35 по Ёжевску. Но одного мы не учли, это время попадаля на урок математики. Пётя, это ёжик в голове которого я родился, уверял меня что все будет хорошо и ничего нам не помешает. У меня были не хорошие предчуствия, но очень уж хотелось на волю, и я согласился.</p>

				<p>Чтобы переместится во внешний мир, меня нужно было нарисовать ровно в выверенное время а потом произнести специальные слова. И вот настало время и Пётя самотверженно начал рисовать меня в тетрадке. Он закончил рисовать и собирался произнести слова, и не заметил как подошла учительница. Она взела у него тетрадь и повертела в поднятой руке, показывая всему классу. Потом она сказала “Пёта, Пётя... Как ты не разучился рисовать всякую дрянь в тетрадках, 7-ой класс уже как ни как. Расскажи ка мне про вынесенние общего множителя за скобки, а то прийдется тебе внести кубический корень из восьми в дневник.”. Я не мог ему помочь, я уже был в тетрадке но пока не мог двигаться. Потом она выдрала страницу со мной, скомкала её и бросила в открытое окно. Что было дальше с Пётей, я не знаю. - ёжик чуть чуть сжался и затих.</p>

				<p> -- А что было дальше? Как ты попал ко мне в голову? - спросил хозяин головки.</p>
				<p> -- Страничку с мной подобрала развернула маленькая ёжевочка и охнула “Какой хорошенький!”. После она завозилась в рюкзаке и достала закругленные ножницы, которыми и вырезала меня по контуру. Вырезала очень аккуратно и старательно, потом она увидела своих подружек и подняв меня в руке закричала “Смотрите кого я нашла!”. В тот миг дунул сильный ветер и я полетел в неизвестность. Не знаю сколько меня носил этото холодный и кусучий ветер, но я очень сильно замерз и стал сворачиваться чтобы согреться. Похоже что мое отчаянье и желание свернуться были настолько сильны что я опять провалился в не явный мир. Там меня уже понес ветер Нави и по моему велению, высадил в теплом безопасном месте, которым оказалась твоя голова... Кстати как тебя зовут?</p>
				<p> -- Смотря кто, друзья Ёшкин-кодом, я програмиствовать люблю. Дома Ёшей зовут.</p>
				<p> -- Хорошо здесь у тебя, уютно, тепло. Но...</p>
				<p> -- Но что!? - чуть ли не обиделся Ёша</p>
				<p> -- Я почти на воле побывал, теперь тоскливо в одном месте сидеть. Понимаешь? - Ёжик посмотрел Ёше прямо в глаза.</p>
				<p> -- Понимаю... - Взохнул успокоившийся Ёша.</p>
				<p> -- Ты мне поможешь?</p>
				<p> -- А как? Опять высчетать время и рисовать тебя?</p>
				<p> -- Не... Тот путь свое отработал и закрылся. Нужен новый...</p>
				<p> -- А если тебя в интерент отправить?</p>
				<p> -- Куда? В интернат?!</p>
				<p> -- Неа, интернет.</p>
				<p> -- А это где?</p>
				<p> -- Это не где, а что. Как бы объяснить... Ну вот представь что ты опять нарисован в тетрадке и можешь там делать все что хочешь. А еще можешь ходить в гости в другие тетрадки к другим ёжикам.</p>
				<p> -- А сколько будет страничек в тетрадке?</p>
				<p> -- Сколько захочешь, точнее  на сколько хватит памяти сервера.</p>
				<p> -- Чей памяти?</p>
				<p> -- Ну это специальный ящик для тетрадок в интернете, а тетрадки кстати “сайтами” называются.</p>
				<p> -- Звучит интерестно... А это сложно сделать?</p>
				<p> -- Со мной нет, я уже хорошо сайты делать умею.</p>
				<p> -- Тогда я согласный, давай делать.</p>
				<p> -- Я сделаю все самое необходимое, а дальше устроишь все сам как тебе нравится.</p>
				<p> ...</p>
				<p>Так и началось создание “Ёжичества”. </p>

			</article>

			<nav className="about-us__pagination">
				<a href="#contents">Оглавление /\</a>
				<a href="#">Поддержать проект</a>
				<a href="#">Во что мы верим</a>
			</nav>
		</section>
	</div>	
    )
}

export default ObNam;
